.dialog-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    max-width: none;
  }
  
.stage-button {
  background-color: lightgray;
  padding: 10px 30px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.stage-button.active {
  background-color: rgb(115, 115, 234);
  color: white;
}

.stage-button.selected {
  border: 4px solid darkblue; 
}
