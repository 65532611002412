.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* light blue background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #f9f9f9; /* light blue background */
    padding: 20px;
    border-radius: 5px;
    color: #00008B; /* dark blue font */
  }