.slider-container {
    position: relative;
    margin-top: 5px;
  }
  
  .horizontal-slider {
    width: 100%;
    height: 25px;
  }
  
  .track {
    background: #ddd;
    height: 5px;
    border-radius: 5px;
  }

  .track-1 {
    background: rgb(127, 127, 250) !important;
    height: 5px;
    border-radius: 5px;
    foreground: rgb(224, 153, 0)
  }
  
  .thumb {
    height: 20px;
    width: 20px;
    background-color: #1976d2; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: #fff;
  }
  
  .thumb-value {
    position: absolute;
    top: -5px;
    background-color: #4CAF50;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
  }
  