
.blujin-appbar {
    width: '100%'; 
    height: 'auto';
    flex-shrink: 0;
    background-color: #FFF;
}

.search-widget {
    background-color: #8f8f8f;
    width: '100%';
}

.neutral {
  color: #121417;
}
.secondary{
    color: #FF7800;
}
.textLight {
    color: #8f8f8f;
}
.textWhite {
    color: #ffffff;
}
.gray {
    color: #56606d;
}
.bluish {
    color: #4787D2;
}

.button {
    display: flex;
    width: 4.125rem;
    height: 2.5rem;
    padding: 0.5rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: #4787D2;
}

.button-style-normal {
    /* button-normal */
    box-shadow: 0px 2px 4px 0px rgba(42, 83, 204, 0.20);
}

.plus-layout {
    display: flex;
    width: 0.9375rem;
    height: 0.9375rem;
    transform: rotate(-45deg);
    padding: 0.11719rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.notification-style {
    width: 0.58344rem;
    height: 1.25019rem;
    flex-shrink: 0;
    
    color: #FFF;
    text-align: right;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0075rem;    
}

.searchRectangle-style {
    width: 72.625rem;
    height: 3.625rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: rgba(217, 217, 217, 0.04);
}


.searchText-style {
    color: var(--text-colour-light-text, #8F8F8F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: 0.01rem;
}
