.sliderChart {
   height: '200px';
   width: '100%';
}

.MuiSlider-root {
    color: #20b2aa;
  }
.MuiSlider-root:hover {
    color: #20b2aa;
}
.MuiSlider-track {
    color: #20b2aa;
}
  
/* .MuiSlider-root:old_hover {
    color: #2e8b57;
  } */

.low {
    color: silver
}
.medium {
    color: orange
}
.high {
    color: green
}

.MuiSlider-thumb {
    border-radius: '4px';
}

.parent {
    position: relative;
    top:  0px;
    left: 0px;
    transform: translate(0%, -0%);
  }
  
.child {
    position: relative;
    bottom: -20px;
    left: 0px;
    transform: translate(0%, 0%);
}
  