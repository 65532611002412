.landing-page {
    display: flex;
    height: 100vh; /* Set the height to 100% of the viewport height */
  }
  
  .side-menu {
    width: 200px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  /* Rest of the styles remain the same */
  