.horizontal-slider {
    width: 100%;
    height: 10px;
  }
  
  .thumb {
    height: 20px;
    width: 20px;
    background-color: rgb(197, 95, 251);
    border-radius: 50%;
    cursor: grab;
  }
  
  .track  {
    background: lightgray !important;
  }
  
  .track-1  {
    background: rgb(127, 127, 250) !important; /* Highlight track between thumbs */
  }