.dropdown {
  position: relative;
}

.dropdown-button {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 80px; /* Set minimum width */
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow-down {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
}

.arrow-up {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(-135deg);
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  min-width:320px; /* Adjust minimum width */
  z-index: 1;
  padding: 10px;
  white-space: nowrap;
}

.dropdown-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.dropdown-content input[type="text"] {
  width: calc(100% - 75px); /* Adjust input width */
}

.option-row {
  display: flex;
  align-items: right;
}

.option-value {
  flex-grow: 1; 
  min-width: 260px;/* Allow option value to grow and take remaining space */
}

.delete-icon {
  cursor: pointer;
  align-items: right;
}
