.candidate-label {
  display: inline-block;
  padding: 5px 10px;
  background-color: #1F4E79;
  font-size: 11px;
  font-family: Calibri, sans-serif;
  color: white;
  border-radius: 5px;
}

.candidate-profile {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  font-family: Calibri, sans-serif;
  color: black;
  border-radius: 5px;
}
.job-profile {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  font-family: Calibri, sans-serif;
  color: black;
  border-radius: 5px;
}

.fit-label {
  display: inline-block;
  padding: 5px 10px;
  background-color: #4472C4;
  font-size: 11px;
  font-family: Calibri, sans-serif;
  color: white;
  border-radius: 5px;
}

.unselectedprofile-label{
  display: inline-block;
  padding: 5px 10px;
  background-color: #BDD7EE;
  font-size: 11px;
  font-family: Calibri, sans-serif;
  color: white;
  border-radius: 5px;
}

.selectedprofile-label{
  display: inline-block;
  padding: 5px 10px;
  background-color: #4472C4;
  font-size: 11px;
  font-family: Calibri, sans-serif;
  color: white;
  border-radius: 5px;
}

.explanation-snackbar{
  width: "250px";
}

.explanation-snackbar-content{
  width: "250px";
  display: inline-block;
  padding: 5px 10px;
  background-color: #9DC3E6;
  font-size: 11px;
  font-family: Calibri, sans-serif;
  color: black;
  border-radius: 5px;
}

